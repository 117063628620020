define('bgr-ember-auth/components/bgr-auth-reset-form', ['exports', 'bgr-ember-auth/config', 'bgr-ember-auth/utils/endpoint', 'bgr-ember-auth/components/bgr-auth-form'], function (exports, _config, _endpoint, _bgrAuthForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bgrAuthForm.default.extend({
    /**
     * Services
     */

    session: Ember.inject.service(),

    /**
     * Arguments
     */

    email: null,
    token: null,

    /**
     * State
     */

    authenticator: 'authenticator:auth-reset',
    endpoint: (0, _endpoint.default)('reset'),

    /**
     * Custom hooks
     */

    dataForRequest(model) {
      let queryParams = this.getProperties('email', 'token');

      return {
        data: {
          attributes: Object.assign(queryParams, model)
        }
      };
    },

    request() {
      if (_config.default.shouldAuthenticateAfterReset) {
        return this.get('session').authenticate(this.get('authenticator'), this.dataForRequest(this.get('model')));
      }

      return this._super(...arguments);
    }
  });
});