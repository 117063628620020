define('bgr-ember-auth/components/bgr-auth-register-form', ['exports', 'bgr-ember-auth/utils/endpoint', 'bgr-ember-auth/components/bgr-auth-form'], function (exports, _endpoint, _bgrAuthForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bgrAuthForm.default.extend({
    /**
     * State
     */

    endpoint: (0, _endpoint.default)('register')
  });
});