define('bgr-ember-auth/utils/endpoint', ['exports', 'bgr-ember-auth/config'], function (exports, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = endpoint;
  function endpoint(name) {
    let endpoint = [];

    if (_config.default.api.host) {
      endpoint.push(_config.default.api.host);
    } else {
      endpoint.push('');
    }

    if (_config.default.api.namespace) {
      endpoint.push(_config.default.api.namespace);
    }

    if (_config.default.api.endpoints[name]) {
      endpoint.push(_config.default.api.endpoints[name]);
    }

    return endpoint.join('/');
  }
});