define('bgr-ember-auth/components/bgr-auth-form', ['exports', 'bgr-ember-auth/errors', 'bgr-ember-auth/templates/components/bgr-auth-form', 'bgr-ember-auth/utils/ajax-options'], function (exports, _errors, _bgrAuthForm, _ajaxOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Services
     */

    ajax: Ember.inject.service(),

    /**
     * State
     */

    action: '', // Needed to display a keyboard submit button on mobile devices.
    attributeBindings: ['action'],
    endpoint: null,
    errors: null,
    isLoading: false,
    isSuccess: false,
    layout: _bgrAuthForm.default,
    model: null,
    tagName: 'form',

    /**
     * Hooks
     */

    init() {
      this._super(...arguments);

      this.model = {};
    },

    submit(event) {
      event.preventDefault();

      this.setProperties({
        errors: null,
        isLoading: true
      });

      let args = [this, this._runAsyncHook];

      this.request().then(Ember.run.bind(...args, this.requestDidSucceed)).catch(Ember.run.bind(...args, this.requestDidFail)).finally(Ember.run.bind(...args, this.requestDidComplete));
    },

    /**
     * Custom hooks
     */

    dataForRequest(model) {
      return {
        data: {
          attributes: model
        }
      };
    },

    extractErrorsFromResponse(response) {
      let errors;

      if (response && response.payload && Array.isArray(response.payload.errors)) {
        errors = response.payload.errors;
      } else {
        errors = [_errors.UNKNOWN_ERROR];
      }

      return errors;
    },

    request() {
      let endpoint = this.get('endpoint');
      let options = (0, _ajaxOptions.default)({
        data: this.dataForRequest(this.get('model'))
      });

      return this.get('ajax').post(endpoint, options);
    },

    requestDidComplete() {
      this.set('isLoading', false);
    },

    requestDidFail(response) {
      this.set('errors', this.extractErrorsFromResponse(response));
    },

    requestDidSucceed() {
      this.set('isSuccess', true);
    },

    /**
     * Private methods
     */

    _runAsyncHook(hook, response) {
      if (!this.get('isDestroying') && !this.get('isDestroyed')) {
        Ember.run(this, hook, response);
      }
    }
  });
});