define('bgr-ember-auth/controllers/auth/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * State
     */

    queryParams: ['email', 'token'],
    email: null,
    token: null
  });
});