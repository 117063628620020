define('bgr-ember-auth/routes/auth/index', ['exports', 'bgr-ember-auth/config', 'bgr-ember-auth/mixins/unauthenticated-route-mixin'], function (exports, _config, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    /**
     * Hooks
     */

    redirect() {
      this.replaceWith(_config.default.defaultRoute);
    }
  });
});