define('bgr-ember-auth/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const UNKNOWN_ERROR = exports.UNKNOWN_ERROR = {
    code: 'unknown',
    detail: 'An unknown error occured. Please check the console for more info.',
    title: 'Unknown Error'
  };
});