define('bgr-ember-auth/authenticators/auth-reset', ['exports', 'bgr-ember-auth/utils/ajax-options', 'bgr-ember-auth/utils/endpoint', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _ajaxOptions, _endpoint, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    /**
     * Services
     */

    ajax: Ember.inject.service(),

    /**
     * State
     */

    endpoint: (0, _endpoint.default)('reset'),

    /**
     * Hooks
     */

    authenticate(data) {
      let endpoint = this.get('endpoint');
      let options = (0, _ajaxOptions.default)({ data });

      return this.get('ajax').post(endpoint, options).then(response => {
        return JSON.parse(response);
      });
    }
  });
});