define('bgr-ember-auth/authenticators/application', ['exports', 'bgr-ember-auth/config', 'bgr-ember-auth/utils/endpoint', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _config, _endpoint, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const API_CREDENTIALS = {
    client_id: _config.default.api.client.id,
    client_secret: _config.default.api.client.secret
  };

  exports.default = _oauth2PasswordGrant.default.extend({
    /**
     * State
     */

    serverTokenEndpoint: (0, _endpoint.default)('login'),

    /**
     * Hooks
     */

    makeRequest(url, data, headers) {
      let dataExtended = Object.assign({}, data, API_CREDENTIALS);

      return this._super(url, dataExtended, headers);
    }
  });
});