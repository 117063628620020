define('bgr-ember-auth/routes/auth/confirm', ['exports', 'bgr-ember-auth/config', 'bgr-ember-auth/mixins/unauthenticated-route-mixin', 'bgr-ember-auth/utils/ajax-options', 'bgr-ember-auth/utils/endpoint'], function (exports, _config, _unauthenticatedRouteMixin, _ajaxOptions, _endpoint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    /**
     * Services
     */

    ajax: Ember.inject.service(),

    /**
     * State
     */

    endpoint: (0, _endpoint.default)('confirm'),

    /**
     * Hooks
     */

    model(params) {
      let endpoint = this.get('endpoint');
      let options = (0, _ajaxOptions.default)({
        data: {
          data: {
            attributes: params
          }
        }
      });

      return this.get('ajax').post(endpoint, options).then(() => {
        return null;
      }).catch(() => {
        this.replaceWith(_config.default.defaultRoute);
      });
    }
  });
});