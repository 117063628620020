define('bgr-ember-auth/components/bgr-auth-login-form', ['exports', 'bgr-ember-auth/errors', 'bgr-ember-auth/components/bgr-auth-form'], function (exports, _errors, _bgrAuthForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bgrAuthForm.default.extend({
    /**
     * Services
     */

    session: Ember.inject.service(),

    /**
     * State
     */

    authenticator: 'authenticator:application',

    /**
     * Custom hooks
     */

    extractErrorsFromResponse(response) {
      let errors;

      if (response) {
        // Workaround for the API returning different error responses.
        errors = response.errors ? response.errors : [response.message ? {
          detail: response.message
        } : _errors.UNKNOWN_ERROR];
      } else {
        // Something went wrong, but we don't know what (e.g. CORS error, 404, ...).
        errors = [_errors.UNKNOWN_ERROR];
      }

      return errors;
    },

    request() {
      return this.get('session').authenticate(this.get('authenticator'), this.get('model.username'), this.get('model.password'));
    }
  });
});