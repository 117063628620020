define('bgr-ember-auth/routes/auth/logout', ['exports', 'bgr-ember-auth/config'], function (exports, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * Services
     */

    session: Ember.inject.service(),

    /**
     * Hooks
     */

    beforeModel() {
      let session = this.get('session');

      if (session.get('isAuthenticated')) {
        session.invalidate();
      } else {
        this.replaceWith(_config.default.defaultRoute);
      }
    }
  });
});