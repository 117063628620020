define('bgr-ember-auth/mixins/data-adapter-mixin', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_dataAdapterMixin.default, {
    /**
     * Hooks
     */

    authorize(xhr) {
      let { access_token } = this.get('session.data.authenticated');

      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }
  });
});