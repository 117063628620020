define('bgr-ember-auth/mixins/application-route-mixin', ['exports', 'bgr-ember-auth/config', 'ember-data/adapters/errors', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _config, _errors, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_applicationRouteMixin.default, {
    /**
     * State
     */

    routeAfterAuthentication: _config.default.routeAfterAuthentication,

    /**
     * Actions
     */

    actions: {
      error(error) {
        if (error instanceof _errors.UnauthorizedError) {
          this.transitionTo(_config.default.defaultRoute);
        }
      },
      logout() {
        this.get('session').invalidate();
      }
    }
  });
});