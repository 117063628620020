define('bgr-ember-auth/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    api: {
      client: {
        id: null,
        secret: null
      },
      endpoints: {
        confirm: null,
        forgot: null,
        login: 'oauth/token',
        register: null,
        reset: null
      },
      host: null,
      namespace: null
    },
    defaultRoute: 'auth.login',
    routeAfterAuthentication: 'app',
    routeIfAlreadyAuthenticated: 'app',
    shouldAuthenticateAfterReset: false
  };
});