define('bgr-ember-auth/controllers/auth/confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * State
     */

    queryParams: ['token'],
    token: null
  });
});