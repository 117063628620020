define('bgr-ember-auth/utils/ajax-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = ajaxOptions;
  const DEFAULT_OPTIONS = {
    contentType: 'application/json',
    dataType: 'text'
  };

  function ajaxOptions(customOptions = {}) {
    return Object.assign({}, DEFAULT_OPTIONS, customOptions);
  }
});