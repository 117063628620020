define('bgr-ember-auth/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    /**
     * State
     */

    authenticationRoute: 'auth.login'
  });
});