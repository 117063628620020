define('bgr-ember-auth/mixins/unauthenticated-route-mixin', ['exports', 'bgr-ember-auth/config', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _config, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {
    /**
     * State
     */

    routeIfAlreadyAuthenticated: _config.default.routeIfAlreadyAuthenticated
  });
});